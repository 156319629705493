
::v-deep .pages-center{
    display: flex;
    justify-content: center;
    margin-top: 24px;
}
.storeGoods{
    /*height: calc(100vh - 160px);*/
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .storeGoodsHeader{
        display: flex;
        justify-content: space-between;
        .issueBtn{
            background: #FD4446;
            color: #FFFFFF;
            font-size: 14px;
            border: none;
            border-radius:2px;
        }
    }
}
.goodsInfo{
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    /*padding-left: 60px;*/
    img{
        width: 58px;
        height: 58px;
    }
    span{
        margin-left: 10px;
    }
}
